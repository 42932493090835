import React, { useState } from "react";
import { useParams } from "react-router-dom";

const QuizView = () => {
  const { id } = useParams();

  const quizData = {
    title: "Quiz de Connaissances Générales",
    questions: [
      {
        question: "Quelle est la capitale de la France ?",
        options: ["Paris", "Londres", "Berlin", "Madrid"],
        correctAnswer: "Paris",
      },
      {
        question: "Qui a écrit 'Les Misérables' ?",
        options: [
          "Victor Hugo",
          "Émile Zola",
          "Honoré de Balzac",
          "Gustave Flaubert",
        ],
        correctAnswer: "Victor Hugo",
      },
      {
        question: "En quelle année a eu lieu la Révolution française ?",
        options: ["1789", "1776", "1815", "1848"],
        correctAnswer: "1789",
      },
      {
        question: "Quel roi de France a été exécuté en 1793 ?",
        options: ["Louis XIV", "Louis XV", "Louis XVI", "Napoléon Bonaparte"],
        correctAnswer: "Louis XVI",
      },
      {
        question: "Quel auteur a écrit 'À la recherche du temps perdu' ?",
        options: [
          "Marcel Proust",
          "Albert Camus",
          "Jean-Paul Sartre",
          "André Gide",
        ],
        correctAnswer: "Marcel Proust",
      },
    ],
  };

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [score, setScore] = useState(null);

  // Handle answer selection
  const handleAnswerClick = (option) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestionIndex]: option,
    }));
  };

  // Handle moving to the next question or submitting the quiz
  const handleNextClick = () => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  // Handle moving to the previous question
  const handleBackClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  // Handle quiz submission and score calculation
  const handleSubmit = () => {
    let newScore = 0;

    quizData.questions.forEach((question, index) => {
      if (userAnswers[index] === question.correctAnswer) {
        newScore += 1;
      }
    });

    setScore(newScore);
  };

  return (
    <div className="min-h-screen flex flex-col items-center p-8">
      <h1 className="text-4xl font-bold text-orange-600 mb-8">
        {quizData.title}
      </h1>

      {score === null ? (
        <div className="w-full max-w-2xl bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-semibold text-blue-600">
            {quizData.questions[currentQuestionIndex].question}
          </h2>
          <ul className="mt-4">
            {quizData.questions[currentQuestionIndex].options.map(
              (option, idx) => (
                <li
                  key={idx}
                  className={`mt-2 p-2 rounded-md cursor-pointer transition-colors ${
                    userAnswers[currentQuestionIndex] === option
                      ? "bg-blue-200 text-blue-700"
                      : "bg-orange-100 text-orange-700 hover:bg-orange-200"
                  }`}
                  onClick={() => handleAnswerClick(option)}
                >
                  {option}
                </li>
              )
            )}
          </ul>
          <div className="flex justify-between mt-6">
            <button
              onClick={handleBackClick}
              disabled={currentQuestionIndex === 0}
              className={`px-4 py-2 rounded font-bold transition ${
                currentQuestionIndex === 0
                  ? "bg-gray-400 text-white cursor-not-allowed"
                  : "bg-orange-600 text-white hover:bg-orange-700"
              }`}
            >
              Retour
            </button>
            <button
              onClick={handleNextClick}
              className="px-4 py-2 bg-orange-600 text-white font-bold rounded hover:bg-orange-700 transition"
            >
              {currentQuestionIndex < quizData.questions.length - 1
                ? "Suivant"
                : "Soumettre"}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-8 p-4 bg-green-100 text-green-700 rounded shadow">
          <h3 className="text-2xl font-bold">
            Votre Score: {score} / {quizData.questions.length}
          </h3>
          {score === quizData.questions.length ? (
            <p className="mt-2 text-lg">
              Félicitations ! Vous avez réussi le quiz !
            </p>
          ) : (
            <p className="mt-2 text-lg">
              Vous avez obtenu {score}. Bonne chance la prochaine fois !
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizView;
