import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import authService from "../../../../../context/authService";
import CreateEvaluationModal from "./components/createEvaluationModal";
import { Link } from "react-router-dom";
import { DNA } from "react-loader-spinner";
import { useSession } from "../../../../../context/SessionContext";

export default function MyCampaigns() {
  const { setSessionDetails } = useSession();
  const [loading, setLoading] = useState(true);
  const [openSessions, setOpenSessions] = useState([]);
  const [endedSessions, setEndedSessions] = useState([]);
  const [displayOpenSessions, setDisplayOpenSessions] = useState(true);
  const [showCreateEvaluationModal, setShowCreateEvaluationModal] =
    useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleRefreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    fetchData();
  });

  const fetchData = async () => {
    try {
      const apiUrl = `https://api.starskills.fr/items/Sessions`;
      const response = await authService.makeAuthenticatedRequest(
        apiUrl,
        "GET"
      );
      const sessions = response.data;
      const SessionsDetails = await Promise.all(
        sessions.map(async (session) => {
          const studentsList = session.List_Students.join(",");
          const SubSkillsList = session.List_SubSkills.join(",");
          const apiUrlStudents = `https://api.starskills.fr/items/Sessions_Students?filter={"id":{"_in":"${studentsList}"}}`;
          const responseStudents = await authService.makeAuthenticatedRequest(
            apiUrlStudents,
            "GET"
          );
          const students = responseStudents.data;
          const studentDetails = await Promise.all(
            students.map(async (student) => {
              const studentId = student.Student_id;
              const studentInfoUrl = `https://api.starskills.fr/users/${studentId}`;
              const studentInfoResponse =
                await authService.makeAuthenticatedRequest(
                  studentInfoUrl,
                  "GET"
                );
              return {
                id: studentInfoResponse.data.id,
                first_name: studentInfoResponse.data.first_name,
                last_name: studentInfoResponse.data.last_name,
                email: studentInfoResponse.data.email,
                relation_id: student.id,
              };
            })
          );
          const apiUrlSubSkills = `https://api.starskills.fr/items/Sessions_SubSkill?filter={"id":{"_in":"${SubSkillsList}"}}`;
          const responseSubSkills = await authService.makeAuthenticatedRequest(
            apiUrlSubSkills,
            "GET"
          );
          const subSkills = responseSubSkills.data;
          const subSkillsDetails = await Promise.all(
            subSkills.map(async (subSkill) => {
              const subSkillId = subSkill.SubSkill_id;
              const subSkillInfoUrl = `https://api.starskills.fr/items/SubSkill/${subSkillId}`;
              const subSkillInfoResponse =
                await authService.makeAuthenticatedRequest(
                  subSkillInfoUrl,
                  "GET"
                );
              return {
                id: subSkillInfoResponse.data.id,
                name: subSkillInfoResponse.data.Name,
                relation_id: subSkill.id,
              };
            })
          );
          return {
            id: session.id,
            Title: session.Title,
            session_students: studentDetails,
            session_subSkills: subSkillsDetails,
            days: session.List_Days,
            Evaluations: session.Evaluations,
            Start_Date: session.Start_Date,
            End_Date: session.End_Date,
            Created_Date: session.date_created,
            Nbreval: session.Nbreval,
          };
        })
      );
      const currentDate = new Date();
      const open = [];
      const ended = [];
      SessionsDetails.forEach((session) => {
        const endDate = new Date(session.End_Date);
        if (endDate >= currentDate) {
          open.push(session);
        } else {
          ended.push(session);
        }
      });

      setOpenSessions(open);
      setEndedSessions(ended);
      setSessionDetails(SessionsDetails);
      console.log("session details", SessionsDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const SessionItem = ({ session }) => {
    return (
      <div className="flex py-6 items-center justify-between p-4 border border-gray-300 shadow mt-2 relative">
        <div>
          <h2 className="text-lg font-semibold text-purple-600 ">
            Titre : {session.Title}
          </h2>
          <p className="text-gray-600">
            <span className="font-semibold">id:</span> {session.id}
          </p>
          <p className="text-gray-600">
            <span className="font-semibold">Start Date:</span>{" "}
            {format(new Date(session.Start_Date), "MM/dd/yyyy")}
          </p>
          <p className="text-gray-600">
            <span className="font-semibold">End Date:</span>{" "}
            {format(new Date(session.End_Date), "MM/dd/yyyy")}
          </p>
          <p className="text-gray-600">
            <span className="font-semibold">Number of Evaluations:</span>{" "}
            {session.Nbreval}
          </p>
        </div>
        <button
          className="text-gray-600 focus:outline-none absolute top-5 right-5"
          onClick={() => console.log("View session details")}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
        {displayOpenSessions && (
          <div className="absolute bottom-6 right-5 ">
            <Link
              to={`/teacher_dashboard/mes_campagnes/create_evaluation/${session.id}`}
              className="bg-purple-600  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Ajouter Evaluation
              <FontAwesomeIcon className="mx-2" icon={faPlus} />
            </Link>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="flex justify-center items-center h-screen">
            <DNA
              visible={true}
              height="100"
              width="100"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </div>
        </div>
      ) : (
        <>
          <nav className="flex mb-4">
            <button
              className={`py-2 px-4 mr-4 text-lg font-semibold ${
                displayOpenSessions
                  ? "text-green-600 border-b-2 border-green-600"
                  : "text-gray-600 border-b-2 border-transparent"
              } focus:outline-none`}
              onClick={() => setDisplayOpenSessions(true)}
            >
              Open Sessions
            </button>
            <button
              className={`py-2 px-4 text-lg font-semibold ${
                !displayOpenSessions
                  ? "text-red-600 border-b-2 border-red-600"
                  : "text-gray-600 border-b-2 border-transparent"
              } focus:outline-none`}
              onClick={() => setDisplayOpenSessions(false)}
            >
              Ended Sessions
            </button>
          </nav>
          <div>
            {displayOpenSessions ? (
              openSessions.length === 0 ? (
                <p className="text-center text-gray-600">
                  Aucune session à afficher
                </p>
              ) : (
                openSessions.map((session) => (
                  <SessionItem key={session.id} session={session} />
                ))
              )
            ) : endedSessions.length === 0 ? (
              <p className="text-center text-gray-600">
                Aucune session à afficher
              </p>
            ) : (
              endedSessions.map((session) => (
                <SessionItem key={session.id} session={session} />
              ))
            )}
          </div>
        </>
      )}
      {/* Create Evaluation Modal */}
      <CreateEvaluationModal
        open={showCreateEvaluationModal}
        onClose={() => setShowCreateEvaluationModal(false)}
        onRefreshPage={handleRefreshPage}
        session={selectedSession}
      />
    </div>
  );
}
