import axios from "axios";

// Load tokens from local storage on initialization
let accessToken = localStorage.getItem("accessToken");
let tokenExpirationTime = localStorage.getItem("tokenExpirationTime");
let refreshToken = localStorage.getItem("refreshToken");

const authService = {
  setAccessToken(token) {
    accessToken = token;
    localStorage.setItem("accessToken", token);
  },

  setTokenExpiration(expiration) {
    tokenExpirationTime = expiration;
    localStorage.setItem("tokenExpirationTime", expiration);
  },

  setRefreshToken(token) {
    refreshToken = token;
    localStorage.setItem("refreshToken", token);
  },

  async refreshAccessToken() {
    try {
      const refreshResponse = await axios.post(
        "https://api.starskills.fr/auth/refresh",
        {
          refresh_token: refreshToken,
          mode: "json",
        }
      );

      accessToken = refreshResponse.data.data.access_token;
      tokenExpirationTime =
        Date.now() + refreshResponse.data.data.expires * 1000;
      refreshToken = refreshResponse.data.data.refresh_token;

      this.setAccessToken(accessToken);
      this.setTokenExpiration(tokenExpirationTime);
      this.setRefreshToken(refreshToken);
    } catch (error) {
      console.error("Error refreshing access token:", error);
      // Handle error, e.g., redirect to login page
    }
  },

  isAccessTokenExpired() {
    return tokenExpirationTime && Date.now() >= tokenExpirationTime;
  },

  async makeAuthenticatedRequest(url, method, data = null) {
    if (this.isAccessTokenExpired()) {
      await this.refreshAccessToken();
    }

    try {
      const response = await axios({
        url,
        method,
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error making authenticated request:", error);
      // Handle error, e.g., redirect to login page
    }
  },
};

export default authService;
