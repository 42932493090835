import React, { useContext, useEffect, useState, Fragment } from "react";
import { useNavigate, Link, Route, Routes } from "react-router-dom";
import controlImage from "../../../../../assets/das/control.png";
import logoImage from "../../../../../assets/brand/logo-icon.svg";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import AuthContext from "../../../../../context/AuthContext";
import Message from "../Message";
import Competence from "../competence";
import Progression from "../progression";
import Feedback from "../feedback";
import Quize from "../quize";

import {
  faEnvelope,
  faChartLine,
  faUserGraduate,
  faComments,
  faClipboardList,
  faChartBar,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StudentElavaluation from "../evaluation/StudentElavaluation";
import StudentRoutes from "../routes/StudentRoutes";
import { DNA } from "react-loader-spinner";
import Compeval from "../campagneEval/compeval";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Studash = () => {
  const [open, setOpen] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState(1);
  const { auth, logout } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (auth) {
      setEmail(auth.email);
      setFirstName(auth.first_name);
      setLastName(auth.last_name);
    }
  }, [auth.id]);

  const handleLogout = () => {
    logout();
    navigate("/");
    window.location.reload();
  };

  const Menus = [
    {
      id: 1,
      title: "Message",
      icon: faEnvelope,
      path: `${auth.id}`,
      content: <Message />,
    },
    {
      id: 2,
      title: "Mes competence",
      icon: faChartLine,
      path: `${auth.id}/competence`,
      content: <Competence />,
    },
    {
      id: 3,
      title: "Ma Progression",
      icon: faClipboardList,
      path: `${auth.id}/progression`,
      content: <Progression />,
    },
    {
      id: 4,
      title: "Mes feedbacks",
      icon: faComments,
      path: `${auth.id}/feedback`,
      gap: true,
      content: <Feedback />,
    },
    {
      id: 5,
      title: "Mes campagnes d’éval.",
      icon: faUserGraduate,
      path: `${auth.id}/compeval`,
      gap: true,
      content: <Compeval />,
    },
    {
      id: 6,
      title: "mes_evaluation",
      icon: faChartBar,
      path: `${auth.id}/auto_evaluation`,
      gap: true,
      content: <StudentElavaluation />,
    },
    {
      id: 7,
      title: "Mes Quizes",
      icon: faQuestionCircle,
      path: `${auth.id}/quize`,
      gap: true,
      content: <Quize />,
    },
    {
      id: 8,
      title: "Vue Mesure",
      icon: faQuestionCircle,
      path: `${auth.id}/quize_view/${auth.id}`,
      gap: true,
      content: <Quize />,
    },
  ];

  const handleMenuClick = (menuId) => {
    setSelectedMenuItem(menuId);
  };

  return (
    <div>
      <div className="flex relative">
        <div
          className={`${
            open ? "w-72" : "w-20 "
          } bg-dark-purple h-auto p-5 shadow-lg pt-8 relative duration-300`}
        >
          <img
            src={controlImage}
            className={`absolute cursor-pointer -right-3 top-9 w-7 border-gray-400
     border-2 rounded-full  ${!open && "rotate-180"}`}
            onClick={() => setOpen(!open)}
            alt="Control"
          />
          <div className="flex  items-center ">
            <img
              src={logoImage}
              className={`cursor-pointer duration-500 ${
                open && "rotate-[360deg]"
              }`}
              alt="Logo"
            />

            <Link
              to="/"
              className={`text-black origin-left font-medium text-xl duration-200 ${
                !open && "scale-0"
              }`}
            >
              Starskills
            </Link>
          </div>
          <ul className="pt-6">
            {Menus.map((menu) => (
              <Link to={`/student_dashboard/${menu.path}`} key={menu.id}>
                <Fragment>
                  <li
                    className={`flex items-center p-2 py-3 cursor-pointer hover:bg-purple-700 hover:text-white text-purple-700 font-semibold text-base mt-4 ${
                      selectedMenuItem === menu.id && "bg-purple-700 text-white"
                    }`}
                    onClick={() => handleMenuClick(menu.id)}
                  >
                    <span className="flex items-center gap-2">
                      <FontAwesomeIcon icon={menu.icon} className="w-5 h-5" />
                      <span
                        className={`${
                          !open && "hidden"
                        } origin-left duration-200`}
                      >
                        {menu.title}
                      </span>
                    </span>
                  </li>
                </Fragment>
              </Link>
            ))}
          </ul>
        </div>

        <div className="h-full flex-1 p-7">
          <div>
            <div className="flex justify-between flex-wrap  items-center">
              <h1 className="text-xl font-semibold">
                {Menus.find((menu) => menu.id === selectedMenuItem).title}
              </h1>

              <div>
                <i className="fa-solid fa-arrow-up text-white"></i>
                {auth.id ? (
                  <>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-orange-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {firstName} {lastName}
                          <ChevronDownIcon
                            className="-mr-1 h-5 w-5 text-gray-800"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <div>
                                  <Link
                                    to={`${auth.id}/mon_profil`}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-black",
                                      "block px-4 py-2 text-sm text-black hover:bg-indigo-700 hover:text-white cursor-pointer"
                                    )}
                                  >
                                    Mon Profile
                                  </Link>

                                  <a
                                    href="/help-center" // Provide a valid href for navigation
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-black",
                                      "block px-4 py-2 text-sm text-black hover:bg-purple-700 hover:text-white cursor-pointer"
                                    )}
                                  >
                                    Centre d'aide
                                  </a>

                                  <button
                                    onClick={handleLogout} // Use button instead of a
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-black",
                                      "block w-full text-left px-4 py-2 text-sm text-black hover:bg-purple-700 hover:text-white cursor-pointer"
                                    )}
                                  >
                                    Deconnecter
                                  </button>
                                </div>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                ) : (
                  <>
                    <p>No user found</p>
                  </>
                )}
              </div>
            </div>
            <div>
              <hr className="my-4 border-t " />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center py-4 items-center">
            <FontAwesomeIcon
              icon={faUserGraduate}
              className="h-6 w-6 sm:mr-2 mb-2 sm:mb-0"
            />
            <h1 className="text-xl font-semibold text-center sm:text-left">
              Sois Le Bienvenue{" "}
              <span className="text-orange-700">
                {firstName} {lastName}
              </span>
            </h1>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <DNA
                visible={true}
                height="100"
                width="100"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <Routes>
              <Route path="/*" element={<StudentRoutes authId={auth.id} />} />
            </Routes>
          )}
        </div>
      </div>
    </div>
  );
};

export default Studash;
