import React from "react";
import Slider from "react-slick";
import "./testimonials.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import person1 from "../../../assets/images/cool3.png";
import person2 from "../../../assets/images/cool2.png";
import person3 from "../../../assets/images/avatar3.jpg";
import person4 from "../../../assets/images/cool7.png";

const testimonials = [
  {
    quote:
      "Toutes mes équipes utilisent l'application avec efficacité. Gain de temps, facilité de suivi, des graphiques personnalisés d'évolution de compétences, identification des progressions et des lacunes de chaque élève. C'est magique!",
    author: "Hakim Durand",
    role: "Ingénieur pédagogique",
    image: person1,
  },
  {
    quote:
      "On peut désormais personnaliser et individualiser avec facilité. Nous 600 étudiants dont on maitrise l'impact précis de nos apprentissages et formation avec facilité. Je conseille l'outil pour les professionnels de l'enseignement, de la formation et l'éducation en général",
    author: "Martine Diop",
    role: "Chargé de cours université",
    image: person2,
  },
  {
    quote:
      "J'ai rarement vu un outil aussi pratique et puissant pour accompagner le développement de compétences. Je le recommande vivement",
    author: "Alicia Ziegler",
    role: "Chargée de formation",
    image: person3,
  },
  {
    quote:
      "Intuitif, facile à prendre en main. Nous appliquons l'approche par compétences avec bonheur. Bravo!",
    author: "Ghislaine Feldman",
    role: "Directrice Centre de formation",
    image: person4,
  },
];

const Testimonials = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <section className="relative isolate overflow-hidden bg-indigo-800 px-6 py-20 lg:px-8">
      <div className="mx-auto max-w-3xl lg:max-w-5xl">
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <figure className="text-center text-white">
                <blockquote className="bg-white rounded-lg shadow-lg p-8">
                  <p className="text-gray-900 text-lg font-medium italic leading-7">
                    {testimonial.quote}
                  </p>
                </blockquote>
                <figcaption className="mt-8 flex flex-col items-center">
                  <img
                    className="h-20 w-20 rounded-full shadow-lg"
                    src={testimonial.image}
                    alt={testimonial.author}
                  />
                  <div className="mt-4 text-lg font-semibold">
                    {testimonial.author}
                  </div>
                  <div className="mt-2 text-sm text-gray-300">
                    {testimonial.role}
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
