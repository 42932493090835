import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Auth/layout";
import { AuthProvider } from "./context/AuthContext";
import NotFoundPage from "./components/Layouts/notFoundPage/NotFoundPage";
import ContactUs from "./pages/ContactUs";
import Terms from "./pages/Terms";
import FAQ from "./pages/FAQ";
import Profilecontent from "./components/dashboard/dashboards/profdash/profile/Profile";
import SignIn from "./pages/identity/SignIn";
import SignUp from "./pages/identity/SignUp";
import Initial from "./pages/identity/onboarding/initial";

import Profdash from "./components/dashboard/dashboards/profdash/main/screens/Teacher_main";
import { SessionProvider } from "./context/SessionContext";
import Studash from "./components/dashboard/dashboards/studash/student_main/Studash";
import AccountVerificationPage from "./pages/identity/AccountVerificationPage";

function App() {
  return (
    <Router>
      <AuthProvider>
        <SessionProvider>
          {" "}
          <Routes>
            <Route element={<Layout />}>
              <Route index path="/" element={<Home />} />
              <Route index path="/contact" element={<ContactUs />} />
              <Route index path="/policy/:id" element={<Terms />} />
              <Route index path="/faq" element={<FAQ />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/onboarding" element={<Initial />} />
              <Route path="/profile" element={<Profilecontent />} />
              {/* <Route
                path="/account_verification"
                element={<AccountVerificationPage />}
              /> */}
              <Route path="/teacher_dashboard/*" element={<Profdash />} />

              <Route path="/student_dashboard/*" element={<Studash />} />

              <Route path="*" element={<NotFoundPage />} />
              <Route path="/unauthorized" element={<div>unauthorized</div>} />
            </Route>
          </Routes>
        </SessionProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
