import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import default_ref from "../../../../../assets/images/ref-image.jpg";
import { DNA } from "react-loader-spinner";
import AddRefModal from "./Components/AddRefModal";
import authService from "../../../../../context/authService";
import CreateSessionModal from "./Components/CreateSessionModal";

const categoryColors = {
  primaire: "bg-pink-100",
  collège: "bg-green-100",
  maternelle: "bg-orange-100",
  "centre de Formation": "bg-purple-100",
  lycée: "bg-blue-100",
};

const buttonColors = {
  primaire: "bg-pink-800",
  collège: "bg-green-600",
  maternelle: "bg-orange-600",
  "centre de Formation": "bg-purple-600",
  lycée: "bg-blue-600",
};

const RefsPage = () => {
  const { category, niveauId } = useParams();

  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateSessionModal, setShowCreateSessionModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);

  const [invitationLink, setInvitationLink] = useState("");
  const [refItems, setRefItems] = useState([]);
  const [refSkills, setrefSkills] = useState(null);

  const [loading, setLoading] = useState(true);

  const [showRefModal, setShowRefModal] = useState(false);

  const handleModalClose = () => {
    setShowCreateSessionModal(false);
    setSelectedItem(null);
  };

  const handleNextStep = () => {
    setCurrentStep(Math.min(currentStep + 1, 4));
  };

  const handlePrevStep = () => {
    setCurrentStep(Math.max(currentStep - 1, 1));
  };

  const handleBoxClick = (selectedRef) => {
    setSelectedItem(selectedRef);
    const selectedItemFromRefItems = refItems.find(
      (item) => item.Name === selectedRef.Name
    );

    if (selectedItemFromRefItems) {
      let totalSkills = 0;
      selectedItemFromRefItems.Skills.forEach((skill) => {
        totalSkills += skill.SubSkills.length;
      });
    }

    setrefSkills(
      selectedItemFromRefItems ? selectedItemFromRefItems.Skills : []
    );
    setShowCreateSessionModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (!authService.accessToken || authService.isAccessTokenExpired()) {
          await authService.refreshAccessToken();
        }

        const apiUrlniveau = `https://api.starskills.fr/items/Referentiel?filter={"Niveau":{"_in":"${niveauId}"}}`;
        const responseGroups = await authService.makeAuthenticatedRequest(
          apiUrlniveau,
          "GET"
        );

        const referentiels = responseGroups.data.map((item) => ({
          id: item.id,
          Name: item.Name,
          Logo: `https://api.starskills.fr/assets/${item.Logo}`,
          Skills: item.Skills,
        }));

        for (const referentiel of referentiels) {
          const skillsList = referentiel.Skills.join(",");
          const apiUrlSkills = `https://api.starskills.fr/items/Skill?filter={"id":{"_in":"${skillsList}"}}`;
          const responseSkills = await authService.makeAuthenticatedRequest(
            apiUrlSkills,
            "GET"
          );
          const skills = responseSkills.data.map((skillItem) => ({
            id: skillItem.id,
            Name: skillItem.Name,
            SubSkills: skillItem.SubSkills,
          }));

          for (const skill of skills) {
            const subSkillsList = skill.SubSkills.join(",");
            const apiUrlSubSkills = `https://api.starskills.fr/items/SubSkill?filter={"id":{"_in":"${subSkillsList}"}}`;
            const responseSubSkills =
              await authService.makeAuthenticatedRequest(
                apiUrlSubSkills,
                "GET"
              );

            const subSkills = responseSubSkills.data.map((subSkillItem) => ({
              id: subSkillItem.id,
              Name: subSkillItem.Name,
            }));

            skill.SubSkills = subSkills;
          }

          referentiel.Skills = skills;
        }

        setRefItems(referentiels);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [category, niveauId]);

  const filteredItems = refItems.filter((item) =>
    item.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOpenRefModal = () => {
    setShowRefModal(true);
  };

  const handleCloseRefModal = () => {
    setShowRefModal(false);
  };

  return (
    <div className="space-y-4">
      <AddRefModal
        showModal={showRefModal}
        handleCloseModal={handleCloseRefModal}
      />
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold text-black">
          Référentiels de :{" "}
          <span className="text-purple-600"> {category} </span>
        </h2>
        <div>
          <button
            onClick={handleOpenRefModal}
            className="bg-purple-600 text-sm text-white px-4 mb-4 py-2 rounded-3xl shadow-md flex items-center"
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Créer un Référentiel
          </button>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Rechercher sur un Référentiel..."
          className="border text-gray-700 border-indigo-700 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-indigo-300"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="container mx-auto">
        {loading ? (
          <div className="flex justify-center">
            <DNA
              visible={true}
              height="100"
              width="100"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {filteredItems.map((item) => (
              <div
                key={item.id}
                className={`rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 ${
                  categoryColors[category] || "bg-gray-100"
                }`}
                onClick={() => handleBoxClick(item)}
              >
                <img
                  src={default_ref}
                  alt={item.Name}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4 flex flex-col items-center">
                  <h3 className="text-lg font-semibold text-gray-800 my-6">
                    {item.Name}
                  </h3>
                  <button
                    className={`${
                      buttonColors[category] || "bg-gray-600"
                    } text-white rounded-full px-4 py-2 shadow-sm transition duration-100 flex items-center gap-2`}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                    Commencer
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <CreateSessionModal
        showCreateSessionModal={showCreateSessionModal}
        handleModalClose={handleModalClose}
        selectedItem={selectedItem}
        currentStep={currentStep}
        refSkills={refSkills}
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        invitationLink={invitationLink}
      />
    </div>
  );
};

export default RefsPage;
