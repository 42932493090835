import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faSave,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import avatar1 from "../../../../../assets/images/2.png";
import Slider from "@mui/material/Slider";
import authService from "../../../../../context/authService";
import AuthContext from "../../../../../context/AuthContext";

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

const StudentEvaluation = () => {
  const [skills, setSkills] = useState([]);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    studentId: "",
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [student, setStudent] = useState({
    name: "",
    avatar: avatar1,
    score: 0,
  });
  const { auth } = useContext(AuthContext);
  const marks = Array.from({ length: 11 }, (_, index) => ({
    value: index * 10,
    label: `${index * 10}`,
  }));

  useEffect(() => {
    if (auth) {
      setUserData({
        firstName: auth.first_name,
        lastName: auth.last_name,
        studentId: auth.id,
      });
      setStudent({
        ...student,
        name: `${auth.first_name} ${auth.last_name}`,
      });
    }
  }, [auth]);

  const valuetext = (value) => `${value}`;

  const [isEditing, setIsEditing] = useState(true);
  const [changedScores, setChangedScores] = useState([]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (successModalOpen) {
      const timer = setTimeout(() => {
        window.location.reload();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [successModalOpen]);

  const fetchData = async () => {
    try {
      const value = "Incomplete";
      const scoreResponse = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Evaluation_Student?filter[Status]=${value}`,
        "GET"
      );
      console.log("evals", scoreResponse);
      if (scoreResponse.data.length === 0) {
        setSkills([]);
      } else {
        const fetchedSkills = await Promise.all(
          scoreResponse.data.map(async (score) => {
            const subSkillInfoUrl = `https://api.starskills.fr/items/SubSkill/${score.SubSkill}`;
            const subSkillInfoResponse =
              await authService.makeAuthenticatedRequest(
                subSkillInfoUrl,
                "GET"
              );
            return {
              id: score.id,
              Evaluation_id: score.Evaluation_id,
              SubSkill_id: score.SubSkill,
              SubSkill_name: subSkillInfoResponse.data.Name,
              score: score.score,
            };
          })
        );
        setSkills(fetchedSkills);

        setStudent((prevStudent) => ({
          ...prevStudent,
          score: fetchedSkills[0]?.score || 0,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setStudent((prevStudent) => ({
      ...prevStudent,
      score: newValue,
    }));

    const updatedScores = [...changedScores];
    updatedScores[currentStep] = newValue;
    setChangedScores(updatedScores);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setStudent((prevStudent) => ({
      ...prevStudent,
      score: changedScores[currentStep + 1] || 0,
    }));
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    setStudent((prevStudent) => ({
      ...prevStudent,
      score: changedScores[currentStep - 1] || 0,
    }));
  };

  const handleSubmitAutoEval = async () => {
    const studentScores = {
      student_name: student.name,
      student_id: userData.studentId,
      studentScores: changedScores.map((score, index) => ({
        SubSkill_name: skills[index]?.SubSkill_name,
        SubSkill_id: skills[index]?.SubSkill_id,
        score: score,
        id: skills[index]?.id,
      })),
    };
    console.log("Auto eval scores:", studentScores);
    try {
      studentScores.studentScores.map(async (score) => {
        const InfoUrl = `https://api.starskills.fr/items/Evaluation_Student/${score.id}`;
        const scoreData = {
          Score: score.score,
          Status: "Complete",
        };
        const scoreResponse = await authService.makeAuthenticatedRequest(
          InfoUrl,
          "PATCH",
          scoreData
        );
        console.log(scoreResponse);
      });
      setSuccessModalOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="container mx-auto mt-4 text-center">
      <h1 className="text-2xl font-bold mb-2 text-white bg-purple-600 inline-block px-6 py-2 rounded-3xl">
        Auto-évaluation synchrone
      </h1>
      <div className="bg-gray-200 w-full pt-4 pb-2 px-2 my-0 mt-6 border relative">
        {skills.length === 0 ? (
          <p className="text-center text-lg text-gray-600">
            Aucune évaluation disponible
          </p>
        ) : (
          <>
            <div className="flex justify-center mb-16">
              <span className="text-xl font-bold">
                <span className="text-gray-600">Compétence : </span>
                {skills[currentStep]?.SubSkill_name}
                <span className="text-orange-600 mx-4">
                  ({currentStep + 1} / {skills.length})
                </span>
              </span>
            </div>
            <div className="mb-4">
              <div className="flex justify-center mb-4">
                <div className="flex items-center">
                  <button
                    className={`${
                      currentStep === 0
                        ? "bg-gray-300 hover:bg-gray-400"
                        : "bg-black hover:bg-purple-700"
                    } text-white font-bold py-2 px-4 rounded-full mr-8`}
                    onClick={handlePrevStep}
                    disabled={currentStep === 0}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <div className="flex items-center mr-8">
                    <img
                      src={student.avatar}
                      alt={student.name}
                      className="w-16 w-16 border border-purple-400 px-3 py-2 bg-purple-400 rounded-full mr-4"
                    />
                    <span>{student.name}</span>
                  </div>
                  <div className="flex items-center">
                    <Slider
                      aria-label="Toujours visible"
                      value={student.score}
                      onChange={handleSliderChange}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      sx={{ width: 300 }}
                      disabled={!isEditing}
                    />
                    <div className="ml-4">
                      <FontAwesomeIcon
                        icon={isEditing ? faSave : faPen}
                        className="mx-4 cursor-pointer text-green-400 text-2xl"
                        onClick={handleEditToggle}
                      />
                    </div>
                  </div>
                  <button
                    className={`${
                      currentStep === skills.length - 1
                        ? "bg-gray-300 hover:bg-gray-400"
                        : "bg-black hover:bg-purple-700"
                    } text-white font-bold py-2 px-4 rounded-full ml-8`}
                    onClick={handleNextStep}
                    disabled={currentStep === skills.length - 1}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>

              {currentStep === skills.length - 1 && (
                <div className="mt-8">
                  <button
                    className={`${
                      changedScores.length === skills.length
                        ? "bg-green-500 hover:bg-green-600"
                        : "bg-gray-300 cursor-not-allowed"
                    } text-white font-bold py-2 px-4 rounded-full mr-4`}
                    onClick={handleSubmitAutoEval}
                    disabled={changedScores.length !== skills.length}
                  >
                    <FontAwesomeIcon icon={faSave} /> Soumettre
                  </button>
                  <button
                    className="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full ml-4"
                    onClick={handlePrevStep}
                  >
                    Retour
                  </button>
                </div>
              )}
            </div>
          </>
        )}

        <div className="bg-gray-900 bg-opacity-50 text-white p-4 mt-12 rounded-lg flex items-center">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          <p>
            Note: Cliquer sur{" "}
            <FontAwesomeIcon icon={faPen} className="mx-1 text-green-200" />{" "}
            pour activer l'édition. Utilisez le curseur pour ajuster les scores.
            Puis, cliquez sur{" "}
            <FontAwesomeIcon icon={faSave} className="mx-1 text-green-200" />{" "}
            pour sauver les modifications et passer à la compétence suivante.
          </p>
        </div>
      </div>
      <Dialog
        size="sm"
        open={successModalOpen}
        toggler={() => setSuccessModalOpen(false)}
      >
        <DialogHeader className="text-green-500 mr-2 flex justify-center text-center border-b border-green-600">
          <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 " />
          Succès
        </DialogHeader>
        <DialogBody>
          <div className="flex justify-center py-4 text-lg">
            Votre évaluation a été enregistrée avec succès.
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default StudentEvaluation;
