import React from "react";
import sections from "./sections";

const Instituts = () => {
  return (
    <div className="flex flex-col items-center bg-gray-50">
      {/* Header Section */}
      <header className="w-full  text-purple-500 text-center py-6 mt-8">
        <h1 className="text-4xl font-bold">Nos Instituts de Formation</h1>
        <p className="mt-2 text-lg">
          Découvrez nos centres spécialisés et leur impact dans différents
          domaines.
        </p>
      </header>

      {/* Main Content */}
      {sections.map((section, index) => (
        <div
          key={section.id}
          className={`flex flex-col md:flex-row ${
            index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
          } items-center bg-white border shadow-sm rounded-lg overflow-hidden my-8 w-11/12 lg:w-10/12 h-auto md:h-[400px]`}
        >
          <div className="md:w-1/2 flex-shrink-0">
            <img
              src={section.image}
              alt="institut"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="md:w-1/2 p-8 lg:p-12 flex flex-col justify-center">
            <h2 className="text-2xl lg:text-3xl font-bold text-orange-600 mb-4">
              {section.header}
            </h2>
            <p className="text-gray-700 text-lg lg:text-xl leading-relaxed">
              {section.text}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Instituts;
