import React from "react";
import {
  faHome,
  faGraduationCap,
  faChalkboardTeacher,
  faUsers,
  faFileAlt,
  faClipboardList,
  faStar,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";

import Decouvrir from "../../Enseignants";
import Enseignants from "../../Decouvrir";
import Rapports from "../../rapports/Rapport_view";
import MyCampaigns from "../../campaigns/MyCampaigns";
import EvaluationLists from "../../evaluation/main_pages/EvalutionsLists";
import Eleves from "../../eleves/Eleves";
import HomeContent from "../../home/homecontent";

const menuData = (niveau) => [
  {
    id: 1,
    title: "Accueil",
    icon: faHome,
    path: "",
    content: <HomeContent />,
  },
  {
    id: 2,
    title: "Decouvrir (Tuto)",
    icon: faGraduationCap,
    path: "decouvrir",
    content: <Decouvrir />,
  },
  {
    id: 3,
    title: "Enseignants",
    icon: faChalkboardTeacher,
    path: "enseignants",
    content: <Enseignants />,
  },
  {
    id: 4,
    title: "Elèves/stagiaires",
    icon: faUsers,
    path: "mes_eleves",
    content: <Eleves />,
  },
  {
    id: 5,
    title: "Rapports",
    icon: faFileAlt,
    path: "Rapports",
    gap: true,
    content: <Rapports />,
  },
  {
    id: 6,
    title: "Mes campagnes d’éval.",
    icon: faClipboardList,
    path: "mes_campagnes",
    gap: true,
    content: <MyCampaigns />,
  },
  {
    id: 7,
    title: "Mes évaluations",
    icon: faChartBar,
    path: "evaluations_list",
    gap: true,
    content: <EvaluationLists />,
  },
  {
    id: 8,
    title: "Mes référentiels",
    icon: faStar,
    path:
      niveau.length > 0
        ? `${niveau[0].Name?.toLowerCase().replace(/\s+/g, "_")}/${
            niveau[0].id
          }`
        : null,
    submenu: niveau
      ? niveau.map((item) => ({
          id: item.id,
          title: item.Name,

          path: item.Name?.toLowerCase().replace(/\s+/g, "_"),
        }))
      : [],
  },
];

export default menuData;
