import React, { useState, useEffect, useContext } from "react";
import {
  faPlus,
  faTimes,
  faTrashAlt,
  faShareAlt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateGroupModal from "./components/CreateGroupModal";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { DNA } from "react-loader-spinner";
import AuthContext from "../../../../../context/AuthContext";
import authService from "../../../../../context/authService";

const Eleves = () => {
  const [loading, setLoading] = useState(true);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showstuModal, setShowstuModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(0);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const { auth } = useContext(AuthContext);
  const [groupData, setGroupData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      const filteredResults = studentOptions.filter((student) =>
        student.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const roleValue = "607323cc-d51f-4c21-8140-0d669b36ca6f";
        const studentUrl = `https://api.starskills.fr/users/?filter={"role":{"_eq":"${roleValue}"}}`;
        const studentSearch = await authService.makeAuthenticatedRequest(
          studentUrl,
          "GET"
        );
        const studentList = studentSearch.data.map((student) => ({
          value: student.id,
          label: student.email,
        }));
        setStudentOptions(studentList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleRemoveStudent = (index) => {
    const updatedStudents = [...selectedStudents];
    updatedStudents.splice(index, 1);
    setSelectedStudents(updatedStudents);
  };

  // Effects
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authService.accessToken || authService.isAccessTokenExpired()) {
          await authService.refreshAccessToken();
        }

        const apiUrlGroups = `https://api.starskills.fr/items/Group?filter[user_created]=${auth.id}`;
        const responseGroups = await authService.makeAuthenticatedRequest(
          apiUrlGroups,
          "GET"
        );
        const groups = responseGroups.data;
        const groupStudentDetails = await Promise.all(
          groups.map(async (group) => {
            const studentsList = group.Students.join(",");
            const apiUrlStudents = `https://api.starskills.fr/items/Group_student?filter={"id":{"_in":"${studentsList}"}}`;
            const responseStudents = await authService.makeAuthenticatedRequest(
              apiUrlStudents,
              "GET"
            );
            const students = responseStudents.data;
            const studentDetails = await Promise.all(
              students.map(async (student) => {
                const studentId = student.student_id;
                const studentInfoUrl = `https://api.starskills.fr/users/${studentId}`;
                const studentInfoResponse =
                  await authService.makeAuthenticatedRequest(
                    studentInfoUrl,
                    "GET"
                  );
                return {
                  id: studentInfoResponse.data.id,
                  first_name: studentInfoResponse.data.first_name,
                  last_name: studentInfoResponse.data.last_name,
                  email: studentInfoResponse.data.email,
                  relation_id: student.id,
                };
              })
            );
            return {
              group_id: group.id,
              group_name: group.Name,
              group_students: studentDetails,
            };
          })
        );

        const data = groupStudentDetails.map((group) => ({
          group_id: group.group_id,
          group_name: group.group_name,
          group_students: group.group_students.map((student) => ({
            id: student.id,
            first_name: student.first_name,
            last_name: student.last_name,
            email: student.email,
            relation_id: student.relation_id,
          })),
        }));
        setGroupData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [auth]);

  const nextGroup = () => {
    setCurrentGroup((prevGroup) => (prevGroup + 1) % groupData.length);
  };

  const prevGroup = () => {
    setCurrentGroup((prevGroup) =>
      prevGroup === 0 ? groupData.length - 1 : prevGroup - 1
    );
  };

  const handleDeleteStudent = (studentId, studentName, relation_id) => {
    setStudentToDelete({
      id: studentId,
      name: studentName,
      relation_id: relation_id,
    });
    setDeleteConfirmationModal(true);
  };

  const confirmDeleteStudent = async () => {
    console.log(`Deleting student with ID: ${studentToDelete.relation_id}`);
    const apiUrl = `https://api.starskills.fr/items/Group_student/${studentToDelete.relation_id}`;
    console.log(apiUrl);
    try {
      const responseStudents = await authService.makeAuthenticatedRequest(
        apiUrl,
        "DELETE"
      );
      console.log(responseStudents);
      console.log("Item deleted successfully");
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    setDeleteConfirmationModal(false);
  };

  const handleOpenCreateGroupModal = () => {
    setShowCreateGroupModal(true);
  };

  const handleCloseModal = () => {
    setSelectedStudents([]);
    setShowstuModal(false);
  };

  const handleSubmitAddCurrentGroup = async () => {
    try {
      const groupId =
        groupData.length > 0 && groupData[currentGroup]
          ? groupData[currentGroup].group_id
          : null;

      if (!groupId) {
        console.error("No current group ID found");
        return;
      }

      const apiUrl = `https://api.starskills.fr/items/Group_student`;

      for (const student of selectedStudents) {
        const groupStudentData = {
          Group_id: groupId,
          student_id: student.value,
        };

        const responseGroupStudent = await authService.makeAuthenticatedRequest(
          apiUrl,
          "POST",
          groupStudentData
        );
      }

      setSelectedStudents([]);

      window.location.reload();
    } catch (error) {
      console.error("Error adding selected students to group:", error);
    }
  };

  const handleDownloadPdf = (studentId) => {
    // Logic to download PDF for the student
    console.log(`Download PDF for student with ID: ${studentId}`);
  };

  const handleShareLink = (studentId) => {
    // Logic to share a link for the student
    console.log(`Share link for student with ID: ${studentId}`);
  };

  const filteredStudents =
    groupData.length > 0 && groupData[currentGroup]
      ? groupData[currentGroup].group_students.filter((student) =>
          student.first_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

  return (
    <div>
      <div>
        <div className="mb-4 w-auto flex justify-between">
          <div className="text-lg font-semibold text-purple-700">
            Total de mes Groupes: {groupData.length}
          </div>
          <button
            className="bg-purple-600  text-sm   text-white px-4 py-2 rounded-2xl flex items-center"
            onClick={handleOpenCreateGroupModal}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Créer nouveau Groupe
          </button>
        </div>

        {/* Group display */}
        <div className="p-4 bg-gray-200 rounded-md shadow-md">
          <div className=" flex justify-start my-3">
            <p className="text-lg font-semibold pt-1 text-indigo-700">
              <spann className="text-gray-600"> nom de groupe :</spann>{" "}
              {groupData.length > 0 &&
                groupData[currentGroup] &&
                groupData[currentGroup].group_name}
            </p>

            <button
              className="bg-indigo-400 text-sm  text-white px-2 py-1 rounded-md flex items-center ml-4"
              onClick={() => setShowstuModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2 " />
              Ajouter au ce groupe
            </button>
          </div>

          {/* Students Cards */}
          <div className="space-y-2">
            {loading ? (
              <div className="flex justify-center">
                <DNA
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : filteredStudents.length === 0 ? (
              <div className="text-center py-4">Groupe vide</div>
            ) : (
              <>
                {/* Header Row */}
                <div className="grid grid-cols-[45%_45%_10%] px-4  text-purple-300 rounded-lg font-semibold">
                  <div>
                    <p>Nom</p>
                  </div>
                  <div>
                    <p>Email</p>
                  </div>
                  <div>
                    <p>Action</p>
                  </div>
                </div>

                {filteredStudents.map((student, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-[40%_40%_20%] p-4 bg-white rounded-lg shadow"
                  >
                    <div>
                      <p className="font-semibold">
                        {student.first_name} {student.last_name}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-bold text-purple-300">
                        {student.email}
                      </p>
                    </div>
                    <div className="flex justify-end space-x-3">
                      <button
                        className="text-blue-400"
                        onClick={() => handleDownloadPdf(student.id)}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </button>
                      <button
                        className="text-green-400"
                        onClick={() => handleShareLink(student.id)}
                      >
                        <FontAwesomeIcon icon={faShareAlt} />
                      </button>
                      <button
                        className="text-red-400"
                        onClick={() =>
                          handleDeleteStudent(
                            student.id,
                            `${student.first_name} ${student.last_name}`,
                            student.relation_id
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          {/* Navigation buttons */}
          <div className="flex justify-center mt-6">
            <button
              className="mr-4 bg-white hover:bg-indigo-700 hover:text-white text-indigo-700 py-2 px-4 rounded-md shadow-md flex items-center"
              onClick={prevGroup}
            >
              <svg
                className="w-6 h-6 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              Précédent
            </button>
            <button
              className="bg-white hover:bg-indigo-700 hover:text-white text-indigo-700 border-gray-700 py-2 px-4 rounded-md shadow-md flex items-center"
              onClick={nextGroup}
            >
              Suivant
              <svg
                className="w-6 h-6 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Create Group Modal */}
        <CreateGroupModal
          open={showCreateGroupModal}
          onClose={() => setShowCreateGroupModal(false)}
          onRefreshPage={handleRefreshPage}
        />

        {/* Add Student  group Modal */}
        <div>
          {showstuModal && (
            <Dialog open={showstuModal} handler={handleCloseModal}>
              <DialogHeader>Ajouter un étudiant</DialogHeader>
              <DialogBody>
                <div className="mb-4">
                  <label
                    htmlFor="studentSearch"
                    className="block text-gray-700 mb-2"
                  >
                    Rechercher des étudiants (par email):
                  </label>
                  <Select
                    id="studentSearch"
                    placeholder="Rechercher par Email d'étudent"
                    isClearable
                    isMulti
                    options={studentOptions}
                    value={selectedStudents}
                    defaultValue={
                      studentOptions.length > 0 ? studentOptions[0] : null
                    }
                    onChange={(selectedOption) => {
                      setSelectedStudents(selectedOption);
                      handleSearch(selectedOption ? selectedOption.value : "");
                    }}
                  />
                </div>

                {/* Display selected students' emails as tags */}
                {selectedStudents.length > 0 && (
                  <div className="mb-4 ">
                    <label className="block text-sm font-medium text-gray-700">
                      Étudiants sélectionnés:
                    </label>
                    <div className="flex flex-wrap bg-gray-200 p-1">
                      {selectedStudents.map((student, index) => (
                        <div
                          key={index}
                          className="flex items-center mr-2 mb-2"
                        >
                          <span
                            className="bg-white border-indigo-600 px-3 py-1 rounded-md mr-2 flex items-center cursor-pointer"
                            onClick={() => handleRemoveStudent(index)}
                          >
                            {student.label}
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="ml-2 text-red-500"
                            />
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {selectedStudents.length === 0 && (
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Aucun étudiant sélectionné
                    </label>
                  </div>
                )}
              </DialogBody>
              <DialogFooter>
                <Button className="mx-2" color="red" onClick={handleCloseModal}>
                  Annuler
                </Button>
                <Button
                  color="green"
                  type="submit"
                  onClick={handleSubmitAddCurrentGroup}
                >
                  Ajouter l'étudiant
                </Button>
              </DialogFooter>
            </Dialog>
          )}

          {/* Delete Student Confirmation Modal */}
          {deleteConfirmationModal && (
            <Dialog
              open={deleteConfirmationModal}
              handler={() => setDeleteConfirmationModal(false)}
            >
              <DialogHeader>Confirmation de suppression</DialogHeader>
              <DialogBody>
                Êtes-vous sûr de vouloir supprimer l'étudiant{" "}
                <strong>{studentToDelete?.name}</strong> ?
              </DialogBody>
              <DialogFooter>
                <Button
                  className="mx-2"
                  color="red"
                  onClick={() => setDeleteConfirmationModal(false)}
                >
                  Annuler
                </Button>
                <Button color="green" onClick={confirmDeleteStudent}>
                  Confirmer
                </Button>
              </DialogFooter>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
};

export default Eleves;
