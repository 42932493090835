import React, { useState, useEffect } from "react";
import authService from "../../../../context/authService";

const Quize = () => {
  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchQuizData();
  }, []);

  const fetchQuizData = async () => {
    try {
      const apiUrl =
        "https://api.starskills.fr/items/Quiz?filter[Type]=Incomplete";
      const response = await authService.makeAuthenticatedRequest(
        apiUrl,
        "GET"
      );
      console.log("quiz", response);
      if (response.data && Array.isArray(response.data)) {
        const formattedData = await Promise.all(
          response.data.map(async (quiz) => {
            const optionsUrl = `https://api.starskills.fr/items/Answers?filter[Question]=${quiz.id}`;
            const optionsResponse = await authService.makeAuthenticatedRequest(
              optionsUrl,
              "GET"
            );

            return {
              ...quiz,
              options: optionsResponse.data.map((answer) => answer.Text),
            };
          })
        );

        setQuizData(formattedData);
      } else {
        setQuizData([]);
      }
    } catch (error) {
      console.error("Error fetching Quiz data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-blue-50 flex flex-col items-center p-8">
      <h1 className="text-4xl font-bold text-orange-600 mb-8">Quiz</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        quizData.map((quiz, index) => (
          <div
            key={index}
            className="w-full max-w-2xl bg-white rounded-lg shadow-md p-6 mb-6"
          >
            <h2 className="text-xl font-semibold text-blue-600">
              {quiz.Question}
            </h2>
            <ul className="mt-4">
              {quiz.options.map((option, idx) => (
                <li
                  key={idx}
                  className="mt-2 p-2 rounded-md bg-orange-100 text-orange-700"
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </div>
  );
};

export default Quize;
