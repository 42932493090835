import React, { useState } from "react";
import why_img from "../../../assets/images/why2.png";

const features = [
  {
    name: "Intéractivité",
    sub_title: "Intéractivité",
    description:
      "Des feedbacks interactifs et personnalisé pour motiver et impliquer l'apprenant dans ses apprentissages",
  },
  {
    name: "Ergonomique",
    sub_title: "Ergonomique",
    description:
      "Une solution rapide et facile à prendre en main, Starskills sera votre allié au quotidien",
  },
  {
    name: "Branding",
    sub_title: "Branding",
    description:
      "Personnalisez vos supports avec votre logo ou les logos de vos partenaires",
  },
];

export default function ImageSection() {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  return (
    <div
      className="flex flex-col lg:flex-row justify-center items-center py-12 px-6 lg:py-20 lg:px-12"
      style={{
        background: "linear-gradient(91.05deg, #073381 8.1%, #052027 103.06%)",
      }}
    >
      <div className="max-w-7xl w-full flex flex-col lg:flex-row items-center">
        {/* Features List */}
        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start lg:pr-6 mb-10 lg:mb-0">
          <ul className="flex justify-center lg:justify-start flex-wrap text-2xl mb-6">
            {features.map((feature) => (
              <li
                key={feature.name}
                className={`cursor-pointer p-3 ${
                  feature === selectedFeature
                    ? "text-yellow-700 border-b-2 border-yellow-700"
                    : "text-white"
                }`}
                onClick={() => setSelectedFeature(feature)}
              >
                {feature.name}
              </li>
            ))}
          </ul>

          <h3 className="text-3xl font-semibold leading-7 text-yellow-700 text-center lg:text-left mb-4">
            {selectedFeature.name}
          </h3>

          <p className="text-lg font-semibold leading-7 text-white text-center lg:text-left">
            {selectedFeature.description}
          </p>
        </div>

        {/* Image */}
        <div className="w-full lg:w-1/2 text-center">
          <img
            src={why_img}
            alt="why us"
            className="mx-auto rounded-xl"
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
}
