import React, { useState, useEffect } from "react";
import Navbar from "../components/Home/Navbar/Navbar";
import Hero from "../components/Home/Hero/Hero";
import Features from "../components/Home/features/Features";
import Footer from "../components/Home/footer/Footer";
import Usage from "../components/Home/Usage/Usage";
import ImageSection from "../components/Home/imageSection/ImageSection";
import WhyUs from "../components/Home/whyUs/WhyUs";
import Testimonials from "../components/Home/testimonials/Testimonials";
import JoinUs from "../components/Home/joinUs/JoinUs";
import Realisation from "../components/Home/realisation/Realisation";
import ImageSlider from "../components/Home/imageSlider/ImageSlider";
import { DNA } from "react-loader-spinner";
import Instituts from "../components/Home/instituts/Instituts";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Navbar />
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <DNA
            visible={true}
            height="100"
            width="100"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : (
        <>
          <Hero />
          <ImageSlider />
          <Instituts />
          <WhyUs />
          <ImageSection />
          <Usage />
          <Features />
          <Realisation />
          <JoinUs />
          <Testimonials />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
