import image1 from "../../../assets/images/student.png";
import image2 from "../../../assets/images/entreprise.png";
import { Carousel } from "@material-tailwind/react";

const ImageSlider = () => {
  return (
    <div
      className=" pb-10 pt-10 px-6 "
      style={{
        height: "70vh",
        background:
          "linear-gradient(100.85deg, #69437c 0.12%, #1F64BF 151.74%)",
      }}
    >
      <Carousel
        autoplay={true}
        className="rounded-xl"
        style={{
          maxWidth: "700px",
          margin: "auto",
          filter: "drop-shadow(0 0 0.6rem black)",
        }}
      >
        <img
          src={image1}
          alt="image 1"
          className="mx-auto rounded-xl"
          style={{ width: "100%", height: "400px", objectFit: "cover" }}
        />
        <img
          src={image2}
          alt="image 2"
          className="mx-auto rounded-xl"
          style={{
            width: "100%",
            height: "400px",
            objectFit: "cover",
            filter: "drop-shadow(0 0 0.6rem black)",
          }}
        />
      </Carousel>
    </div>
  );
};

export default ImageSlider;
